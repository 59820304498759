import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Clipboard from 'react-clipboard.js';
import DouyinAd from './DouyinAd';

const useStyles = theme => ({
})

class TextAd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vis: true,
            copyHint: '[点击复制]'
        };
    }

    onSuccess = () => {
        this.setState({ copyHint: '复制完成!' })
        setTimeout(() => {
            this.setState({ copyHint: '[点击复制]' })
        }, 1200);
    }

    onError = () => {
        this.setState({ copyHint: '手动复制' })
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
            </div>
        );
    }
}
export default withStyles(useStyles)(TextAd);