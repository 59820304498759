import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Paper, Container } from '@material-ui/core';

const useStyles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        display: 'flex',
        flexWrap: 'wrap'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
})

class UserResearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    render() {
        const { classes } = this.props;
        return (
            <Container maxWidth="md">
                <Paper className={classes.paper}>
                    <iframe
                        id="goldendata_form_717cKd"
                        src="https://jinshuju.net/f/717cKd?background=white&banner=show&embedded=true"
                        width="100%"
                        frameBorder={0}
                        height="575">
                    </iframe>
                </Paper>
            </Container>
        );
    }
}
export default withStyles(useStyles)(UserResearch);