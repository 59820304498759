import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Routes from './Routes';
import { Container, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';
import amber from '@material-ui/core/colors/amber';
import './App.css'
import Clipboard from 'react-clipboard.js';

const useStyles = theme => ({
  header: {
    backgroundColor: '#fff',
  },
  headGrid: {
    height: theme.spacing(8),
  },
  logo: {
    height: theme.spacing(6),
  },
  footer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: '#fff',
    textAlign: 'center',
    color: '#febd00',
  },
  footerText: {
    fontSize: '80%',
    letterSpacing: '0.2em',
    margin: '0.2em',
  },
  smallText: {
    fontSize: '60%',
    letterSpacing: '0.2em',
    margin: '0.2em',
    color: '#6b6b6b'
  },
  footerGrid: {
    marginBottom: -8
  },
  qrcodeImg: {
    width: '3rem',
    margin: theme.spacing(1),
  },
  popText: {
    letterSpacing: '0.1em',
    fontSize: '70%',
    textAlign: 'center',
  },
  collectImg: {
    height: '20rem'
  },
  collectFrame: {
    height: '20rem'
  }
})

const theme = createMuiTheme({
  shadows: [
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
    '0px 0px 0px 0px red,0px 0px 0px 0px red,0px 0px 0px 0px red',
  ],
  palette: {
    primary: amber,
    secondary: {
      main: '#78a929',
      contrastText: '#fff'
    },
  },
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    fontSize: 14,
  },
});


class App extends Component {
  constructor() {
    super();
    this.state = {}

  }

  render() {
    const { classes } = this.props;
    return (
      <div >
        <ThemeProvider theme={theme}>
          <header className={classes.header}>
            <Container maxWidth="md">
              <Grid container alignItems="center" className={classes.headGrid} spacing={1}>
                <Grid item>
                  <Link to="/"><img alt="柠檬精" className={classes.logo} src={require('./static/images/lemonjing3.png')} /> </Link>
                </Grid>

                <Grid item>
                  <Link to="/" style={{ textDecoration: 'none' }} ><Button color="primary" size="small" variant="outlined">主页</Button></Link>
                </Grid>
              </Grid>
            </Container>
          </header>
          <Routes></Routes>

          <footer className={classes.footer}>
            <Container maxWidth='md'>
              <Grid container justify="center" alignItems="center" spacing={2}>
                <Grid item className={classes.footerGrid}>
                  <Typography className={classes.footerText}>
                    <Link to="/" style={{ textDecoration: 'none' }} ><Button color="primary" size="small" variant="outlined">LEMONJING主页</Button></Link>
                    &nbsp;|&nbsp;由<a href="https://liust.me">@LiuST</a>不自豪地制作 &nbsp;

                  </Typography>
                  <Typography className={classes.footerText}><a href="mailto:liust97@qq.com">邮件联络</a> <Link to="/terms">条款</Link> <a href="https://www.freepik.com/">部分图源Freepik</a></Typography>
                </Grid>

                <Grid item className={classes.footerGrid}>
                  <Grid container alignItems="center" justify="center" >
                    <Grid item>
                      <Typography className={classes.popText}>关</Typography>
                      <Typography className={classes.popText}>注</Typography>
                      <Typography className={classes.popText}>作</Typography>
                      <Typography className={classes.popText}>者</Typography>
                    </Grid>
                    <Grid item>
                      <img alt="微博头像" src={require('./static/images/headshot.jpg')} className={classes.qrcodeImg} />
                      <Typography className={classes.popText}><a href="https://www.weibo.com/u/3228472547">微博</a></Typography>
                    </Grid>
                    <Grid item>
                      <img alt="公众号二维码" src={require('./static/images/qrcode.jpg')} className={classes.qrcodeImg} />
                      <Typography className={classes.popText}>公众号</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          </footer>
        </ThemeProvider>
      </div >
    );
  }
}
export default withStyles(useStyles)(App);