import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Paper, Typography } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import MicIcon from '@material-ui/icons/Mic';
import StopIcon from '@material-ui/icons/Stop';
import AudioAnalyser from "react-audio-analyser"
import Waveform from './Waveform'
import DeleteIcon from '@material-ui/icons/Delete';
import ReactGA from '../components/ReactGaWrapper';
// import Wavesurfer from 'react-wavesurfer';

// In my bundle config this is setup to export to window.WaveSurfer

const useStyles = theme => ({
    curChar: {
        color: '#f67280',
    },
    wrapper: {
        textAlign: "center",
        padding: theme.spacing(2),
        height: 530,
        overflow: 'hidden'
    },
    fill: {
        backgroundColor: '#e8efdd',
        marginTop: theme.spacing(2),
        height: 317,
    },
    recordTimer: {
        paddingTop: 20,
        paddingBottom: 20,
        backgroundColor: "#edfbd6",
        color: "#689f0f"
    }
})

class Reverser extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: "",
            audioSrc: "",
            reverseSrc: "",
            clientWidth: document.body.clientWidth,
            second: 0
        };
        this.timer = null
    }


    componentDidMount = () => {
        // window.addEventListener('resize', this.resize);
        this.props.onRef(this)
    }

    // resize = () => {
    //     this.setState({
    //         clientWidth: document.body.clientWidth
    //     })
    // }

    controlAudio = () => {
        if (this.state.status === "recording") {
            this.setState({
                status: "inactive"
            })
            this.props.onStatusChange(true)
            clearInterval(this.timer);
        } else {
            if (this.state.audioSrc) {
                this.restart()
                this.props.onStatusChange(false)
            } else {
                this.setState({
                    status: "recording"
                })
                this.setState({
                    second: 0
                })
                this.timer = setInterval(() => {
                    this.setState({
                        second: Math.round(this.state.second * 100 + 1) / 100
                    })
                }, 10);
            }
        }
    }
    restart = () => {
        this.setState({audioSrc: null, reverseSrc: null, second: 0})
    }
    handleTogglePlay = () => {
        this.setState({
            playing: !this.state.playing
        });
    }
    handlePosChange = (e) => {
        this.setState({
            pos: e.originalArgs[0]
        });
    }
    reverseBuffer = (buffer, context) => {
        const reverse = context.createBuffer(
            buffer.numberOfChannels,
            buffer.length,
            buffer.sampleRate
        );

        for (let channels = 0; channels < buffer.numberOfChannels; channels++) {
            const dest = reverse.getChannelData(channels);
            const src = buffer.getChannelData(channels);

            for (let sampleFrames = 0; sampleFrames < buffer.length; sampleFrames++) {
                dest[sampleFrames] = src[buffer.length - sampleFrames];
            }
        }

        return reverse;
    }

    // from https://www.russellgood.com/how-to-convert-audiobuffer-to-audio-file/
    bufferToWave = (abuffer, len) => {
        var numOfChan = abuffer.numberOfChannels,
            length = len * numOfChan * 2 + 44,
            buffer = new ArrayBuffer(length),
            view = new DataView(buffer),
            channels = [], i, sample,
            offset = 0,
            pos = 0;

        // write WAVE header
        setUint32(0x46464952);                         // "RIFF"
        setUint32(length - 8);                         // file length - 8
        setUint32(0x45564157);                         // "WAVE"

        setUint32(0x20746d66);                         // "fmt " chunk
        setUint32(16);                                 // length = 16
        setUint16(1);                                  // PCM (uncompressed)
        setUint16(numOfChan);
        setUint32(abuffer.sampleRate);
        setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
        setUint16(numOfChan * 2);                      // block-align
        setUint16(16);                                 // 16-bit (hardcoded in this demo)

        setUint32(0x61746164);                         // "data" - chunk
        setUint32(length - pos - 4);                   // chunk length

        // write interleaved data
        for (i = 0; i < abuffer.numberOfChannels; i++)
            channels.push(abuffer.getChannelData(i));

        while (pos < length) {
            for (i = 0; i < numOfChan; i++) {             // interleave channels
                sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
                sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0; // scale to 16-bit signed int
                view.setInt16(pos, sample, true);          // write 16-bit sample
                pos += 2;
            }
            offset++                                     // next source sample
        }

        // create Blob
        return new Blob([buffer], { type: "audio/wav" });

        function setUint16(data) {
            view.setUint16(pos, data, true);
            pos += 2;
        }

        function setUint32(data) {
            view.setUint32(pos, data, true);
            pos += 4;
        }
    }

    getTime = (second) => {
        let min = Math.floor(second / 60)
        min = min < 10 ? "0" + min : min
        let sec = second % 60
        sec = sec.toFixed(2)
        sec = sec < 10 ? "0" + sec : sec
        return min + ":" + sec
    }
    render() {
        const { classes } = this.props;
        const { status, audioSrc } = this.state;
        const wavepara = {
            backgroundColor: this.props.backgroundColor,
            waveColor: '#a5a5a5',
            progressColor: this.props.strokeColor,
            cursorColor: this.props.strokeColor,
            barWidth: 3,
            cursorWidth: 1,
            height: this.props.waveHeight,
            barGap: 1
        }
        const audioProps = {
            audioType: "audio/wav",
            // audioOptions: {sampleRate: 30000}, // 设置输出音频采样率
            status,
            audioSrc,
            timeslice: 1000, // 时间切片（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
            backgroundColor: this.props.backgroundColor,
            strokeColor: this.props.strokeColor,
            className: this.props.waveName,
            width: Math.min(375, this.state.clientWidth / 2 - 70),
            height: this.props.waveHeight,
            startCallback: (e) => {
            },
            pauseCallback: (e) => {
            },
            stopCallback: (e) => {
                this.setState({
                    audioSrc: window.URL.createObjectURL(e)
                })
                const context = new AudioContext()
                let fileReader = new FileReader();
                let arrayBuffer;

                fileReader.onloadend = () => {
                    arrayBuffer = fileReader.result;
                    context.decodeAudioData(arrayBuffer, (buffer) => {
                        const reverse = this.reverseBuffer(buffer, context);
                        const blob = this.bufferToWave(reverse, reverse.sampleRate * reverse.duration)
                        this.setState({
                            reverseSrc: window.URL.createObjectURL(blob),
                        })
                    });
                }
                fileReader.readAsArrayBuffer(e);

                ReactGA.event({
                    category: 'reverse',
                    action: 'stop record'
                  })
            },

            onRecordCallback: (e) => {
            },
            errorCallback: (err) => {
                alert("无法录音，请根据页面底部提示尝试其他浏览器。报错内容：" + err)
                ReactGA.event({
                  category: 'reverse',
                  action: 'error'
                })
            }
        }

        return (
            <div>
                <Paper className={classes.wrapper}>
                    <Typography contentEditable="true" suppressContentEditableWarning="true" variant="h6">{this.props.title}</Typography>
                    <AudioAnalyser {...audioProps} className={classes.analyser}>
                    </AudioAnalyser>
                    {/* <Grid className={classes.recordTimer} container justify="center">
                        <Typography variant="h5">
                            {this.getTime(this.state.second)}
                        </Typography>
                    </Grid> */}
                    <Fab
                        className="btn"
                        color='primary'
                        onClick={this.controlAudio}
                        disabled={!this.props.enable}
                        >
                        {(status === "recording") ? <StopIcon /> : (this.state.audioSrc ? <DeleteIcon/> : <MicIcon />)}
                    </Fab>
                    <div className='wrapper'>
                        {
                            (this.state.audioSrc) ?
                                <div><br /><Waveform buttonname="正放" src={this.state.audioSrc} wavepara={wavepara} /></div> :
                                null
                        }
                        {
                            (this.state.reverseSrc) ?
                                <div><br /><Waveform buttonname="倒放" reverseHighlight={this.props.reverseHighlight} src={this.state.reverseSrc} wavepara={wavepara} /></div> :
                                <div>
                                    <Grid container justify="center" alignItems="center" className={classes.fill}>
                                        <Typography>{this.props.fillText}</Typography>
                                    </Grid>
                                </div>
                        }
                    </div>
                </Paper>
            </div>
        );
    }
}
export default withStyles(useStyles)(Reverser);

Reverser.defaultProps = {
    enable: true,
    title: "录音倒放",
    backgroundColor: "#edfbd6",
    strokeColor: "#689f0f",
    waveHeight: 100,
    waveName: "audioContainer",
    onStatusChange: ()=>{},
    reverseHighlight: false,
    fillText: "还没有录制音频"
}