import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { Container, Paper, Box } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Divider from '@material-ui/core/Divider';
import ReactGA from '../components/ReactGaWrapper';
import Grid from '@material-ui/core/Grid';
import TextAd from '../components/TextAd';
import UserResearch from '../components/UserResearch';

const useStyles = theme => ({
    cardGrid: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    media: {
        paddingTop: '25%', // 16:9
    },
    mainWrapper: {
        backgroundColor: "#e8efdd",
        textAlign: "center",
    },
    content: {
        paddingTop: theme.spacing(1)
    },
    superWrapper: {
        maxWidth: 600,
        margin: 'auto'
    },
    imgWrapper: {
        width: '100%',
        height: 0,
        paddingBottom: '32%'
    },
    mainImg: {
        width: "100%",
    },
    about: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        display: 'flex'
    },
    aboutLeft: {
        flex: '0 0 70px'
    },
    aboutRight: {

    },
    largeAvatar: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        border: '5px solid #e8f3d7'
    },
    webTitle: {
        color: '#78a929'
    },
    linksWrapper: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    paperTitle: {
        margin: 3,
    },
    paperContent: {
        padding: theme.spacing(1),
        color: 'rgba(0, 0, 0, 0.75)'
    },
})
class Home extends Component {
    constructor() {
        super();
        this.state = {}

    }
    componentDidMount() {
        document.title = "柠檬精LEMONJING-首富模拟器，童年模拟器，倒放挑战";
        // (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.mainWrapper}>
                    <Container maxWidth="md">
                        <div className={classes.superWrapper}>
                            <div className={classes.imgWrapper}>
                                <img alt="用酸创造快乐" className={classes.mainImg} src={require("../static/images/home_main.png")}></img>
                            </div>
                        </div>
                    </Container>
                </div>
                <Container maxWidth="md" >
                    <UserResearch/>
                    <Grid container spacing={2} className={classes.cardGrid}>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/rich" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/money.jpg")}
                                        title="money"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            首富模拟器
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            体验首富的感觉，花光两千七百亿元。
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/childhood" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/candy.jpg")}
                                        title="candy"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            童年模拟器
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            穿越回到童年，回味千禧年代的共同回忆。
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6}>
                            <Card className={classes.card}>
                                <CardActionArea component={Link} to="/daofang" style={{ textDecoration: 'none' }} disableRipple>
                                    <CardMedia
                                        className={classes.media}
                                        image={require("../static/images/cards/mic.jpg")}
                                        title="倒放挑战图片"
                                    />
                                    <CardContent className={classes.content}>
                                        <Typography variant="h6" component="h2" className={classes.webTitle}>
                                            倒放挑战
                                 </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            首个在线倒放挑战网站。（千万别念“一把石手”）
                                </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                    {/* <ins className="adsbygoogle"
                        style={{ display: 'inline-block', width: '100%', height: 100, margin: '10px auto' }}
                        data-ad-client="ca-pub-5404682594654148"
                        data-ad-slot="9737268302">
                    </ins> */}
                    <TextAd />
                    <br />

                    <Paper className={classes.linksWrapper}>
                        <Typography className={classes.paperTitle}>友情链接<span style={{ color: '#555', fontSize: '80%' }}>（欢迎交换友链）</span></Typography>
                        <Divider />
                        <List dense={true}>
                            <ListItem button component="a" href="http://shadiao.pro/" target="_blank" onClick={() => {
                                ReactGA.event({
                                    category: 'links',
                                    action: 'shadiao app'
                                })
                            }}>
                                <ListItemAvatar>
                                    <Avatar variant="rounded" alt="沙雕APP logo" src={require("../static/images/links/shadiao_icon.png")} />
                                </ListItemAvatar>
                                <ListItemText primary="「沙雕APP」系列作品" secondary="骂人宝典 / 彩虹屁生成器 / 毒鸡汤" />
                            </ListItem>
                        </List>
                    </Paper>
                    <Paper className={classes.linksWrapper}>
                        <Typography className={classes.paperTitle}>关于</Typography>
                        <Divider />
                        <div className={classes.paperContent}>
                            <Typography variant='body2'>2019年11月，我将“首富模拟器”发布在lemonjing.com。五天后，超过60万的用户访问了这个网站。12月，我重新设计了这个网站，让它成为我的无聊网页作品合集。</Typography>
                            {/* <br/>
                            <Typography variant="body2">
                                喜欢这个
                            </Typography> */}
                        </div>
                    </Paper>
                </Container>
            </div >
        );
    }
}
export default withStyles(useStyles)(Home);