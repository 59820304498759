import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Clipboard from 'react-clipboard.js';

const useStyles = theme => ({
})

class DouyinAd extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vis: true,
            copyHint: '[点击复制]'
        };
    }

    onSuccess = () => {
        this.setState({ copyHint: '复制完成!' })
        setTimeout(() => {
            this.setState({ copyHint: '[点击复制]' })
        }, 1200);
    }

    onError = () => {
        this.setState({ copyHint: '手动复制' })
    }
    render() {
        const { classes } = this.props;
        return (
            <Typography
                style={{
                    textAlign: 'center',
                    color: '#555',
                    backgroundColor: '#eeefce',
                    lineHeight: 2.5,
                    borderRadius: 6,
                }} variant='body2' hidden={!this.state.vis}>
                <input
                    style={{
                        width: 125,
                        backgroundColor: 'rgba(255,255,255,0.6)',
                        border: '1px solid grey',
                        borderRadius: 4,
                        color: '#555',
                    }} type="text" defaultValue={this.props.code} />&nbsp;

                <Clipboard component='a' data-clipboard-text={this.props.code} onSuccess={this.onSuccess}>
                    {this.state.copyHint}&nbsp;
                </Clipboard>
                后打开抖音领红包&emsp;{this.props.isClose ? <a onClick={() => this.setState({ vis: false })}>❎</a> : null}
            </Typography>
        );
    }
}
export default withStyles(useStyles)(DouyinAd);

DouyinAd.defaultProps = {
    isClose: false,
    code: ''
}