import React from 'react';

export default class Terms extends React.Component {

    render() {
        return (

            <div style={{ margin: 15, fontSize: 14 }}>
                <p>凡在本网站出现的信息，均仅供参考。本网站将尽力确保所提供信息的准确性及可靠性，但不
                    保证有关资料的准确性及可靠性，注册用户和一般页面游览者在使用前请进一步核实，并对任何自主决定
                    的行为负责。本网站对有关资料所引致的错误、不确或遗漏，概不负任何法律责任（包括侵权责任、合同
                    责任和其它责任）。
                </p>
                <p>任何透过本网站网页而链接及得到的资讯、产品及服务，本网站概不负责，亦不负任何法律责任。</p>
                <p>任何单位或个人认为本网站中的网页或链接内容可能涉嫌侵犯其知识产权，应该及时向本网站提
                    出书面权利通知，并提供身份证明、权属证明及详细侵权情况证明。本网站在收到上述法律文件后，将会依
                    法尽快删除相关内容或断开相关链接。</p>
            </div>
        );
    }
}