import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Paper } from '@material-ui/core';
import { Link } from "react-router-dom";

const useStyles = theme => ({
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: '33.33%',
        flexShrink: 0,
        display: 'flex',
        flexWrap: 'wrap'
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
    },
    paper: {
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
})

class UserResearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            vis: true,
        };
    }
    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.paper} hidden={!this.state.vis}>
                <Typography variant='body2'>填写这个<span role='img'>👉</span><Link to='/survey'>简短的问卷</Link><span role='img'>👈</span>将对改进这个网站很有帮助，谢谢！<a onClick={() => this.setState({ vis: false })} variant="outlined">[关闭]</a></Typography>
            </Paper>
            // <ExpansionPanel style={{ margin: '15px auto' }}>
            //     <ExpansionPanelSummary
            //         expandIcon={<ExpandMoreIcon />}
            //         aria-controls="panel1a-content"
            //         id="panel1a-header"
            //         style={{ backgroundColor: '#e8efdd' }}>
            //         <div className={classes.heading}>
            //             <Typography>小调查<span role="img">👋</span></Typography>
            //             <Typography variant='body2'>请点击</Typography>
            //         </div>
            //         <Typography className={classes.secondaryHeading}>我是一个认真的开发者，填写这个<b>简短</b>的问卷将对改进这个网站很有帮助。</Typography>
            //     </ExpansionPanelSummary>
            //     <ExpansionPanelDetails>
            //         <iframe
            //             id="goldendata_form_717cKd"
            //             src="https://jinshuju.net/f/717cKd?background=white&banner=show&embedded=true"
            //             width="100%"
            //             frameBorder={0}
            //             height="575"></iframe>
            //     </ExpansionPanelDetails>
            // </ExpansionPanel >
        );
    }
}
export default withStyles(useStyles)(UserResearch);